exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-info-js": () => import("./../../../src/pages/company-info.js" /* webpackChunkName: "component---src-pages-company-info-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-creation-js": () => import("./../../../src/pages/creation.js" /* webpackChunkName: "component---src-pages-creation-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovation-js": () => import("./../../../src/pages/innovation.js" /* webpackChunkName: "component---src-pages-innovation-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */)
}

